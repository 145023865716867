@import "../mixins.scss";

.carousel-3d-container {
  height: 460px !important;
  padding: 20px 0;
  .carousel-3d-slider {
    .carousel-3d-slide {
      display: flex;
      background: no-repeat;
      border: none;
      box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
      .carousel-slider-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        width: 100%;
        height: 100%;
      }
    }
  }
  .carousel-3d-controls {
    .prev,
    .next {
      border-radius: 8px;
      overflow: hidden;
      transition: 0.3s;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(3px);
        filter: blur(3px);
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("#{$VUE_APP_BASE}/assets/icons/slider-arrow.svg") no-repeat center;
      }
      span {
        display: none;
      }

      &:hover {

      }
    }

    .prev {
      left: 0;
    }

    .next {
      right: 0;
      transform: rotate(180deg);
    }
  }
}

.business-slider-mobile {
  display: none;
}

@include mobile {
  .business-slider-no-mobile {
    display: none;
  }
  .business-slider-mobile {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    padding: 15px 0 15px 20px;
    display: block;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .business-slider-mobile-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .business-slider-mobile-image {
    min-width: 297px;
    height: 186px;
    background-size: cover;
    box-shadow: 0px 4px 16px 0px #00000014, 0px 0px 16px 0px #00000014;
  }
}
