@import "../colors.scss";
@import "../mixins.scss";

.main-screen {
  .main-screen-content {
    position: relative;
    z-index: 0;
    max-width: 1280px;
    max-height: 720px;
    margin: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0 40px;
    .presentation-bg {
      position: absolute;
      width: 100%;
      height: 100vh;
      max-height: 1200px;
      max-width: 1200px;
      background-repeat: no-repeat;
      bottom: -90%;
      left: 5%;
      z-index: -3;
    }

    &:before,
    &:after {
      background-repeat: no-repeat;
      background-position: 130% 10%;

      position: absolute;
      z-index: -1;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .text-block-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      background: linear-gradient(90deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0) 100%);
    }
    .text-block {
      width: 100%;
      max-width: 640px;
      h1 {
        font-family: MTSExtendedBlack;
        text-transform: uppercase;
        font-size: 56px;
        line-height: 44px;
        margin-bottom: 2.5rem;
      }
      p {
        @include prime-text-normal-uncolored;
        max-width: 528px;
        line-height: 24px;
        margin-bottom: 72px;
      }
    }

    .text-footer-block {
      width: 100%;
      max-width: 640px;
    }

    .btn,
    .btn-text {
      padding: 0;
      border: none;
      max-width: 270px;
      min-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        padding: 10px;
        background: inherit;
        height: auto;
        .buttonContentDH {
          @include medium-text-small-uncolored;
          line-height: 20px;
          color: $white-text;
        }
      }
      &:hover {
        transform: scale(1.02);
      }
    }

    @include intermediate {
      max-height: 600px;
    }

    @include tablet {
      .presentation-bg {
        left: 0;
      }
    }

    @include mobile {
      &:before {
        background-position: 100% 75%;
        background-size: 130%;
      }

      .text-block-wrap {
        padding: 5rem 20px 2rem 20px;
        justify-content: space-between;
        background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0.5) 30%, rgba(255,255,255,0) 100%);

      }
      .text-block {
        h1 {
          font-size: 32px;
          margin-bottom: 3rem;
        }
        p {
          br {
            display: none;
          }
        }
      }
      .text-footer-block {
        display: flex;
        justify-content: center;
        .btn {
          max-width: 330px;
        }
      }
    }
  }
}
.main-screen-content-pages {
  padding-top: 150px !important;
  height: auto !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start !important;
}
.main-screen-full-map {
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/images/landing/map.png');
    background-position: 100% 60%;
    background-size: 90%;
  }
}
.main-screen-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.main-screen-info-text {
  width: 40%;
}
.main-screen-title {
  font-family: MTSExtendedBlack;
  text-transform: uppercase;
  font-size: 56px;
  line-height: 55px;
  margin-bottom: 2.5rem;
  white-space: pre;
  &.main-screen-title-long {
    font-size: 44px;
  }
}
.main-screen-info-text-description {
  @include prime-text-normal-uncolored;
  max-width: 460px;
  line-height: 24px;
  margin-bottom: 2.5rem;
}
.main-screen-button {
  transition-duration: 0.4s;
  .buttonDH {
    background: $button-red !important;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: $dark-text;
    }
  }
  .buttonContentDH {
    text-align: center;
    font-family: MTSCompactMedium;
    font-size: 17px !important;
    color: $light-white-text !important;
  }
  &:hover {
    transform: scale(1.02);
  }
}
.main-screen-button-mobile {
  display: none;
}
.main-screen-info-slider {
  width: 60%;
  .prev, .next {
    top: -60px;
  }
}
.main-screen-info-slider-desktop {
  display: block;
}
.main-screen-info-slider-mobile {
  display: none;
}
.city-map {
  //background-position: right;
  //background-repeat: no-repeat;
  //background-image: url('#{$VUE_APP_BASE}/assets/images/landing/backgrounds/map-big.svg');
}
@include tablet-wide {
  .main-screen-content-pages {
    padding-top: 100px !important;
  }
  .main-screen-pages-title {
    margin-bottom: 20px;
  }
  .main-screen-info-text-description {
    margin-bottom: 72px;
  }
  .main-screen-button {
    .buttonDH {
      padding: 4px !important;
    }
    .buttonContentDH {
      font-size: 14px !important;
    }
  }
}
@include tablet {
  .main-screen-info {
    flex-direction: column;
  }
  .main-screen-title {
    font-size: 32px;
    line-height: 22px;
    white-space: unset;
    &.main-screen-title-long {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .main-screen-info-text-description {
    font-size: 14px;
    line-height: 20px;
  }
  .main-screen-info-text {
    width: 100%;
  }
  .main-screen-info-slider {
    width: 100%;
  }
  .main-screen-info-slider-desktop {
    display: none;
  }
  .main-screen-info-slider-mobile {
    display: block;
    .carousel-3d-container {
      height: 230px !important;
    }
    .next, .prev {
      top: 0;
    }
  }
  .main-screen-button-mobile {
    display: block;
    width: 268px;
    margin: 0 auto;
    margin-top: 32px;
    .btn {
      width: 100% !important;
      max-width: unset !important;
    }
  }
  .main-screen-button-desktop {
    display: none;
  }
}

@include mobile {
  .main-screen-content-home-page {
    padding: 0 !important;
  }
  .main-screen-content-pages {
    padding-top: 80px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .main-screen-title {
    font-size: 20px;
    &.main-screen-title-long {
      font-size: 16px;
    }
  }
  .main-screen-button-mobile {
    display: block;
    width: 100%;
    .btn {
      width: 100%;
      max-width: unset !important;
    }
  }
  .main-screen .main-screen-content .text-footer-block .btn {
    max-width: unset;
    width: 100%;
  }
  .main-screen .main-screen-content .text-block p {
    font-size: 14px;
  }
  .text-block-wrap {
    width: 100vw;
  }
  .main-screen-title-long {
    font-size: 16px;
    line-height: 22px;
  }
}
