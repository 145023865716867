@import "../colors.scss";
@import "../mixins.scss";

.data-presentation-pages {
  margin-top: 100px;
  padding-bottom: 70px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.data-presentation-pages-block-container {
  width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
  &.compact {
    //padding: 0;
    .data-presentation-pages-block-title {
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }
  }
  &.animated {
    .data-presentation-pages-block {
      animation-duration: 5s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      &:first-child {
        animation-name: dataPresentation1;
      }

      &:nth-child(2) {
        animation-name: dataPresentation2;
      }

      &:nth-child(3) {
        animation-name: dataPresentation3;
      }

      &:nth-child(4) {
        animation-name: dataPresentation4;
      }

      &:nth-child(5) {
        animation-name: dataPresentation5;
      }
    }
  }
}
.data-presentation-pages-block {
  width: 268px;
  max-height: 165px;
  opacity: 0;
}
.data-presentation-pages-block-title {
  line-height: 30px;
  margin-bottom: 16px;
  font-family: MTSWideMedium;
  font-size: 20px;
  color: $dark-text;
  span {
    font-family: MTSWideMedium;
    font-size: 20px;
    color: $dark-text;
  }
}
.data-presentation-pages-block-additional {
  @include prime-text-small;
}
@include below-desktop {
  .data-presentation-pages-block-container {
    width: 100%;
  }
}

@include tablet-wide {
  .data-presentation-pages-block-container {
    &.compact {
      padding: 0 20px;
    }
  }
  .data-presentation-pages-block {
    min-width: 276px;
  }
  .data-presentation-pages-block-title {
    &.small {
      font-size: 24px;
      span {
        font-size: 24px;
      }
    }
  }
}

@include mobile {
  .data-presentation-pages-block-container {
    padding-left: 20px;
    padding-right: 20px;
    min-width: unset;
    flex-direction: column;
    gap: 48px;
  }
  .data-presentation-pages-block-title {
    margin-bottom: 12px;
    font-size: 16px;
    span {
      font-size: 16px;
    }
  }
  .data-presentation-pages-block {
    width: 100%;
    min-width: unset !important;
  }
}
